import {
  defaultShouldDehydrateQuery,
  type Query,
  QueryCache,
  QueryClient,
  type QueryKey,
} from '@tanstack/react-query';

import isBrowser from '@/utils/isBrowser';

export const getDefaultStaleTime = (): number => 1000 * 60 * 5;

export const shouldDehydrateQuery = (
  query: Query<unknown, Error, unknown, QueryKey>,
): boolean =>
  defaultShouldDehydrateQuery(query) || query.state.status === 'pending';

export const makeQueryClient = (): QueryClient =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: getDefaultStaleTime(),
      },
      dehydrate: {
        // include pending queries in dehydration
        shouldDehydrateQuery,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        // @TODO: Update this to Datadog when it's fully implemented
        // eslint-disable-next-line no-console
        console.error('Error fetching data with react-query:', error, query);
      },
    }),
  });

let browserQueryClient: QueryClient | undefined = undefined;

export const getQueryClient = () => {
  if (!isBrowser()) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
};
